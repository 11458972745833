import React from "react";
import Container from 'react-bootstrap/Container';

function NotFound(){
    return (
        <Container>
            <div className="align-self-center">
                <div className="text-center">
                    <h1 className="display-1 fw-bold silkscreen hero-title">404</h1>
                    <p><span className="text-danger">Oops!</span> Page not found.</p>
                </div>
            </div>
        </Container>
    );
};

export default NotFound;