import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import httpClient from "../common/httpClient";
import Alert from 'react-bootstrap/Alert';
import { useState, useEffect } from 'react';
import { useForm } from "react-hook-form"

function ManageCategory({ categoryListUrl, categorySaveUrl }) {

  const { register, handleSubmit, setValue } = useForm();
  const [category, setCategory] = useState(null);
  const [categoryList, setCategoryList] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [alert, setAlert] = useState(0);

  const showAlert = () => {
    if(alert){
      return (
          <Alert key={"warning"} variant={"warning"}>
            {alert}
          </Alert>
      )
    }
    return ""
  }

  const GetCategories = async (categoryListUrl) => {
    try {
      const resp = await httpClient.get(categoryListUrl);
      if(resp.data.error){
        alert(`Error: ${resp.data.error}`);
      }
      setCategoryList(resp.data.categories)
    } catch(error) {
      console.log(error);
    }
  }

  useEffect(()=>{
    GetCategories(categoryListUrl);
    // eslint-disable-next-line
  }, [categoryListUrl]);

  const SetInputName = (category) => {
    if(parseInt(category)){
      const result = categoryList.filter((cat) => cat.id ===  parseInt(category))
      setValue('categoryName', result[0].name);
    } else {
      setValue('categoryName', '')
    }
    
  }

  const SetInputParent = (category) => {
    if(parseInt(category)){
      const result = categoryList.filter((cat) => cat.id ===  parseInt(category))
      if(result.length > 0) {
        if(result[0].parent_id){
          const resultParent = categoryList.filter((cat) => cat.id ===  result[0].parent_id)
          if(resultParent.length > 0){
            setValue("parentId", resultParent[0].id);
          } else {      
            setValue("parentId", '');
          }
        } else {      
          setValue("parentId", '');
        }
      } else {      
        setValue("parentId", '');
      }
    } else {
      setValue("parentId", '');
    }
  }

  useEffect( () => {
    SetInputName(category);
    SetInputParent(category);
    // eslint-disable-next-line
  }, [category])

  const createOptions = (filter=null) => {
    let options = [];
    categoryList.map( (el, i) => {
      if(el.id!==filter){
        options.push(<option key={i} value={el.id}>{el.name}</option>)
      }
      return ""
    })
    return options;
  }

  const onSubmit = async (data) => {
    data.categoryId = category;
    if(!categorySaveUrl) return;
    setSubmitting(true);    

    try {
      const resp = await httpClient.post(categorySaveUrl, data);
      if(resp.data.error != null){
        setAlert(resp.data.error);
      } else {
        setAlert(null);
        GetCategories(categoryListUrl);
        setValue('categoryName', '')
        setValue('parentId', '')
      }
    } catch (error) {
      setAlert(error.data.error);
    }
    setSubmitting(false);
  }

  const GetForm = () => {
    return (
      <Form className='mt-3 pt-3 border-top' onSubmit={handleSubmit(onSubmit)}>

        <Form.Group className="mb-3" controlId="displayName">
          <Form.Label>Category Name</Form.Label>
          <Form.Control type="text" disabled={submitting} placeholder="Enter category name" {...register("categoryName", {required: true})} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="parentId">
          <Form.Label>Parent</Form.Label>
          <Form.Select aria-label="Default select example" disabled={submitting} {...register("parentId")}>
            <option value="">None</option>
            {createOptions(category)}
          </Form.Select>
        </Form.Group>

        <Button variant="primary" disabled={submitting} type="submit">
          Save
        </Button>
      </Form>
    )
  }

  return (
    <Container fluid>
      {showAlert()}
        <Form.Select onChange={(e) => { setCategory(e.target.value); }}>
          <option>Add New</option>
          {createOptions()}
        </Form.Select>
        {GetForm()}
    </Container>
  );
}

export default ManageCategory;