import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/esm/Container";
import { useParams } from "react-router-dom";
import httpClient from "../common/httpClient";
import moment from 'moment';
import Image from 'react-bootstrap/Image';

function SinglePost({ postUrl }){

    const [loading, setLoading] = useState(false);
    const {id, name} = useParams();
    const [modalContent, setModalContent] = useState("");
    const [modalImage, setModalImage] = useState("");
    const [modalUpdateDate, setModalUpdateDate] = useState("");
    const [modalCreatedDate, setModalCreatedDate] = useState("");

    const getPost = async () => {
        if (postUrl != null && id != null) {
            setLoading(true);
            try {
                const resp = await httpClient.get(
                    `${postUrl}?postId=${parseInt(id)}`
                ).then( 
                    r => {
                        setModalContent(r.data.post.data.text)
                        setModalUpdateDate(r.data.post.updated_at)
                        setModalCreatedDate(r.data.post.created_at)
                        if(r.data.post.image){
                            setModalImage(r.data.post.image)
                        }
                    }
                )
            } catch (error) {
                console.log(error);
            }
            setTimeout(() => {
                setLoading(false);
            }, 500)
        }
    }
    useEffect( () => {
        getPost()
    }, [postUrl])

    return (
        <Container className="py-5">
            <div className='mb-3'>
                <small><i>Created on {moment(new Date(modalCreatedDate)).fromNow()}</i></small>
                { modalUpdateDate > modalCreatedDate && 
                (
                    <small><i> ( Last updated on {moment(new Date(modalUpdateDate)).fromNow()} )</i></small>
                )}
            </div>
            {modalImage ? (
                <div style={{height:300, display:"flex", alignItems:"center"}} className='w-100 mb-5 text-center overflow-y-hidden'>
                    <Image src={modalImage} className='w-100' fluid/>
                </div>
            ) : ("")}
            <div className="content" dangerouslySetInnerHTML={{__html: modalContent}}></div>
        </Container>
    )

}

export default SinglePost;