import React from "react";
import Posts from "./Posts.js";

function Home({ links }){
    return (
        <>
            {<Posts postsLink={links.posts} postUrl={links.post}/>}
        </>
    );
};

export default Home;