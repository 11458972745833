import React from "react";
import Container from 'react-bootstrap/Container';
import Stack from 'react-bootstrap/Stack';
import ButtonMailto from "../common/ButtonMailTo";
import { Link } from "react-router-dom";

function Contact(){
    return (
        <Container className="col-xxl-8 px-4 py-5">
            <h1 className="display-5 fw-bold lh-1 mb-3 hero-title silkscreen">Contact</h1>
            <Stack gap={3}>
                <ButtonMailto label="via E-Mail" mailto="mailto:richard@richardmark.dev" />
                <Link to={"https://twitter.com/r3sbarra"}>X (Formerly Twitter)</Link>
            </Stack>
        </Container>
    );
};

export default Contact;