import React, { useEffect, useState } from "react";
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import CategoryBadge from "./CategoryBadge";
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

function Post({post, index, showPostModal}){

    const navigate = useNavigate()

    const PostCardStyle = (post) => {
        if(!'image' in post) return ""
        return (
            {
                backgroundImage: `url("${post.image}"), linear-gradient(rgba(0,0,0,0.01), rgba(0,0,0,0.5))`, 
                backgroundBlendMode: "overlay",
                maxHeight: 400,
            }
        )
    }

    const getLargest = (date1, date2) => {
        if(date1 > date2) return moment(new Date(date1)).fromNow();
        return moment(new Date(date2)).fromNow();
    }

    const handleCategoryClick = (e, category_name) => {
        e.stopPropagation();
        e.preventDefault();
        navigate(`/cat/${category_name}`)
    }

    const handleLinkClick = (e) => {
        e.preventDefault();
    }

    return (<Col key={index}>
            <Card onClick={(e) => {showPostModal(post.id)}} className="rounded-0 border-1 card-cover h-100 text-bg-dark overflow-hidden" 
                style={PostCardStyle(post)}> 
                <div className="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1">
                    <h3 className="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold overflow-hidden" style={{textOverflow: "ellipsis"}}>
                        <Link onClick={e => handleLinkClick(e)} to={`post/${post.id}/${post.display_name}`} className="link-body-emphasis link-offset-2 link-underline-opacity-25 link-underline-opacity-75-hover">
                            {post.display_name}
                        </Link>
                    </h3>
                    <ul className="d-flex list-unstyled mt-auto">
                        <li className="me-auto">
                            <CategoryBadge href={`/cat/${post.category_name}`} onClick={e => (handleCategoryClick(e, post.category_name))} category={post.category_name}/>
                        </li>
                        <li className="d-flex align-items-center">
                            <small>{getLargest(post.created_at, post.updated_at)}</small>
                        </li>
                    </ul>
                </div>
                <a className="btn btn-secondary rounded-0"></a>
            </Card>
        </Col>
    );
};

export default Post;