import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Error from "../common/Error";
import httpClient from "../common/httpClient";
import Post from "../common/Post";
import Spinner from 'react-bootstrap/Spinner';
import PostModal from "./PostModal";
import InfiniteScroll from 'react-infinite-scroller';
import { useParams } from "react-router-dom";

function Posts({postsLink, postUrl}){
    const [posts, setPosts] = useState([]);
    const [hasError, setHasError] = useState(false);
    const [offset, setOffset] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [hasMore, setHasMore] = useState(true);
    const [showPostModal, setShowPostModal] = useState(false);
    const [currentPost, setCurrentPost] = useState(null);
    const { category } = useParams();

    const getPostsLinkUrl = () => {
        if(category){
            return postsLink+"?offset="+offset+"&category="+category;
        }
        return postsLink+"?offset="+offset;
    }

    const gatherPosts = async () => {
        if(postsLink){

            try {
                await httpClient.get(getPostsLinkUrl()).then( resp => {
                    if(resp.data.posts.length <= 0){
                        setHasMore(false);
                    } else {
                    setPosts(posts => [ ...posts, ...resp.data.posts]);
                }
                setHasError(false);
                setOffset(offset+1);
                }).finally( r => {
                    setIsLoading(false);
                })
            } catch (error) {
                console.log("ERROR ", error);
                setHasError(true);
            }
        }
    }
    
    const showPost = (post_id) => {
        setShowPostModal(true);
        setCurrentPost(post_id);
    }

    const handleClosePostModal = () => {
        setShowPostModal(false);
    }
    
    useEffect( () => {
        gatherPosts();
    }, [postsLink])

    if(isLoading){
        return (
            <>
            <div className="text-center py-5 mb-5">
                <Spinner animation="grow" />
            </div>
            </>
        )
    }
    
    if(hasError){
        return (
            <>
            <Error error={"500"} message={`Failed to get posts :(`} />
            </>
        )
    }

    if(posts.length > 0){
        return (
            <>
            { showPostModal && (
                <PostModal postUrl={postUrl} handleClose={handleClosePostModal} show={showPostModal} postId={currentPost} />
            )}
            <Container fluid className="px-0">
                <InfiniteScroll
                    key={"infinityScroll"}
                    hasMore={hasMore}
                    loadMore={gatherPosts}
                    loader={<p key={"loading"}>Loading...</p>}
                >
                    <Row key={"postRow"} xs={1} lg={2} xxl={4} className="g-0 align-items-stretch">
                            {posts.map( (post, index) => {
                                return (<Post post={post} index={index} key={index} showPostModal={showPost} />)    
                            })}
                    </Row>
                </InfiniteScroll>
            </Container>
            </>
        );
    } else {
        return (
            <Error error={"Oops!"} message={"No posts found :("}/>
        );
    }
};

export default Posts;