import React, {useEffect, useState} from "react";
import Badge from 'react-bootstrap/Badge';
import { Link } from "react-router-dom";

function CategoryBadge({href,  category, onClick}){

    const [bg, setBg] = useState('secondary')
    const [cssClass, setCssClass] = useState("")

    useEffect(()=>{
        switch(category){
            case "Blog":
                setBg("secondary")
                setCssClass("text-dark")
                break
            case "App":
                setBg("warning")
                setCssClass("text-dark")
                break
            case "Game":
                setBg("danger")
                setCssClass("text-dark")
                break
            case "Misc":
                setBg("info")
                setCssClass("text-dark")
                break
            default:
                setBg("secondary")
                break
        }
    }, [category])

    if(href){
        return (
            <Link to={href}>
                <Badge 
                    onClick={onClick} 
                    bg={bg} 
                    style={{cursor: "pointer" }}
                    className={"rounded-0 silkscreen link-underline link-underline-opacity-0 "+cssClass}
                    >
                        {category}
                </Badge>
            </Link>
        )
    }
    return (
        <Badge 
            onClick={onClick} 
            bg={bg} 
            style={{cursor: "pointer" }}
            className={"rounded-0 silkscreen link-underline link-underline-opacity-0 "+cssClass}
        >
                {category}
        </Badge>
    )
};

export default CategoryBadge;