import React from "react";
import Container from 'react-bootstrap/Container';

function Error({ error, message }){
    return (
        <Container className="align-middle my-5 py-5">
            <div className="align-self-center">
                <div className="text-center">
                    <h1 className="display-1 fw-bold silkscreen hero-title">{error}</h1>
                    <p>{message}</p>
                </div>
            </div>
        </Container>
    );
};

export default Error;