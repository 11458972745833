import { BrowserRouter, Routes, Route } from "react-router-dom"
import React, { useEffect, useState } from "react";
import httpClient from "./common/httpClient";
import Home from "./home/Home"
import About from './home/About';
import Contact from './home/Contact';
import NotFound from "./common/NotFound";
import SiteNavigation from "./common/Navbar";
import UserModal from './home/UserModal';
import Posts from './home/Posts';
import SinglePost from "./home/SinglePost";
import { UserContext } from "./common/UserContent";

export default function App() {

    const [discoveryLinks, setDiscoveryLinks] = useState(0);
    const [clickCount, setClickCount] = useState(0);
    const [showUserModal, setShowUserModal] = useState(false);
    const [user, setUser] = useState(null);

    const countAdd = () => {
        setClickCount(clickCount+ 1);

        if(clickCount>=5){
            setShowUserModal(true);
            setClickCount(0);
        }
    };

    const assignCurrentUser = async () => {
        if(discoveryLinks){
            try {
                const resp = await httpClient.get(discoveryLinks.user);
                if(!resp.data.error){
                    setUser(resp.data);
                }
            } catch(error) {
                console.log(error)
            }
        }
    }
    useEffect(()=>{
        assignCurrentUser();
    }, [discoveryLinks])

    useEffect( ()=>{
        (async () => {
            try {
                const resp = await httpClient.get(process.env.REACT_APP_API_URL_DISCOVER);
                setDiscoveryLinks(resp.data);
                assignCurrentUser();
            } catch (error) {
                console.log("Failed to get links" + error)
            }
        })();
    }, []);

    return (
        <>
        <UserContext.Provider value={user}>
            
            {<SiteNavigation userModalCountIncrease={countAdd}/>}
            {showUserModal ? (
                <UserModal links={discoveryLinks} show={showUserModal} setShow={setShowUserModal} userCheck={assignCurrentUser}/>
                ) : ("")
            }

            <BrowserRouter>
                <Routes path="/">
                    <Route index element={<Home links={discoveryLinks} />} />
                    <Route path="about" element={<About/>} />
                    <Route 
                        path="cat/:category"
                        element={<Posts 
                            postsLink={discoveryLinks.posts} 
                            postUrl={discoveryLinks.post} />
                        }
                    />
                    <Route 
                        path="post/:id/:name?"
                        element={
                            <SinglePost postUrl={discoveryLinks.post} />
                        }
                    />
                    <Route path="contact" element={<Contact/>} />
                    <Route path="*" element={<NotFound/>} />
                </Routes>
            </BrowserRouter>

            <div className="container-fluid px-0 border-top mt-3">
                <div className='container'>

                    <footer className="d-flex flex-wrap justify-content-between align-items-center py-2 my-2">
                        <div className="col-md-4 d-flex align-items-center">
                            <span className="mb-3 mb-md-0 text-body-secondary">© 2024 richardmark.dev</span>
                        </div>

                    </footer>
                </div>
            </div>
        
        </UserContext.Provider>
        </>
    );
};
