import Modal from 'react-bootstrap/Modal';
import React, { useState, useEffect } from "react";
import Spinner from 'react-bootstrap/Spinner';
import httpClient from '../common/httpClient';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/esm/Container';
import moment from 'moment';

function PostModal({ show, handleClose, postId, postUrl }) {

    const [loading, setLoading] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState("");
    const [modalImage, setModalImage] = useState("");
    const [modalUpdateDate, setModalUpdateDate] = useState("");
    const [modalCreatedDate, setModalCreatedDate] = useState("");

    const getPost = async () => {
        if (postUrl != null && postId != null) {
            setLoading(true);
            try {
                const resp = await httpClient.get(
                    `${postUrl}?postId=${parseInt(postId)}`
                ).then( 
                    r => {
                        setModalTitle(r.data.post.display_name)
                        setModalContent(r.data.post.data.text)
                        setModalUpdateDate(r.data.post.updated_at)
                        setModalCreatedDate(r.data.post.created_at)
                        if(r.data.post.image){
                            setModalImage(r.data.post.image)
                        }
                    }
                )
            } catch (error) {
                console.log(error);
            }
            setTimeout(() => {
                setLoading(false);
            }, 500)
        }
    }
    useEffect( () => {
        getPost()
    }, [postUrl])

    return (
        <Modal
            show={show}
            onHide={handleClose}
            fullscreen
            centered
            backgrop="static"
            size="xl"
            scrollable={true}
        >
            {loading ? (
                <div className='d-flex justify-content-center py-5'>
                    <Spinner animation="grow" />
                </div>
            ) : (
                <>
                    <Modal.Header closeButton>
                        <Modal.Title>{modalTitle}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body >
                        <Container>
                            <div className='mb-3'>
                                <small><i>Created on {moment(new Date(modalCreatedDate)).fromNow()}</i></small>
                                { modalUpdateDate > modalCreatedDate && 
                                (
                                    <small><i> ( Last updated on {moment(new Date(modalUpdateDate)).fromNow()} )</i></small>
                                )}
                            </div>
                            {modalImage ? (
                                <div style={{height:300, display:"flex", alignItems:"center"}} className='w-100 mb-5 text-center overflow-y-hidden'>
                                    <Image src={modalImage} className='w-100' fluid/>
                                </div>
                            ) : ("")}
                            <div className="content" dangerouslySetInnerHTML={{__html: modalContent}}></div>

                        </Container>
                    </Modal.Body>
                </>
            )}

        </Modal>
    );
}

export default PostModal;