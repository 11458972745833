import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Hero({type, title, data})
{

    return (
        <Container className="col-xxl-8 px-4 py-5">
            <Row className="align-items-center g-5">
                <Col>
                    <h1 className="display-5 fw-bold lh-1 mb-3 silkscreen hero-title">{title}</h1>
                    <p className="lead">{data.lead}</p>
                </Col>
            </Row>
        </Container>
    )
}

export default Hero;