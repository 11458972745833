import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import LoginForm from '../common/LoginForm';
import Nav from 'react-bootstrap/Nav';
import { useState, useContext } from 'react';
import RegisterForm from '../common/RegisterForm';
import Alert from 'react-bootstrap/Alert';
import ManageCategory from './ManageCategory';
import ManagePosts from './ManagePosts';
import { UserContext } from '../common/UserContent';


function UserModal({ show, setShow, links, userCheck }) {
    const user = useContext(UserContext);
    const handleClose = () => setShow(false);
    const [userFormState, setUserFormState] = useState('login');
    const [modalFormTitle, setUserFormTitle] = useState('Login form');
    const [alert, setAlert] = useState(0);

    const handleNavBtn = (state_name, modal_title) => {
        setUserFormState(state_name);
        setUserFormTitle(modal_title);
    }

    const showAlert = () => {
        if (alert) {
            return (
                <Alert key={"warning"} variant='warning'>
                    {alert}
                </Alert>
            )
        }
        return ""
    }
    
    if (user) {

        const handlePostBtn = () => handleNavBtn("post", "Manage Posts");
        const handleCategoryBtn = () => handleNavBtn("category", "Manage Categories");

        const NavItems = () => {
            if (links.register) {
                return (
                    <Nav fill variant="underline" defaultActiveKey="manage-post" className='mb-3'>
                        <Nav.Item>
                            <Nav.Link eventKey="manage-post" onClick={handlePostBtn}>Post</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="manage-category" onClick={handleCategoryBtn}>Category</Nav.Link>
                        </Nav.Item>
                    </Nav>
                )
            }
        }

        const ModalContent = () => {
            
            switch(userFormState){
                case "category":
                    return (<ManageCategory categoryListUrl={links.category} categorySaveUrl={links.categorysave}/>)
                case "post":
                    return (<ManagePosts postsListUrl={links.postlist}  postUrl={links.post}  postSaveUrl={links.postsave} categoryListUrl={links.category} />)
                default:
                    return (<p>Nothing to see here</p>);
            }
        }

        return (
            <>
                <Modal
                    show={show}
                    onShow={handlePostBtn}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    centered
                    fullscreen={true}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{modalFormTitle} (logged in as "{user.display_name}")</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {showAlert()}
                        {NavItems()}
                        {ModalContent()}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    } else {
        // Login Register area
        const handleRegisterBtn = () => handleNavBtn("register", "Register Form");
        const handleLoginBtn = () => handleNavBtn("login", "Login Form");

        const DisplayedForm = () => {
            if (userFormState === 'register' && links.register) {
                return <RegisterForm alertCallback={setAlert} registerLink={links.register} callback={handleClose} userCheck={userCheck} />
            }
            return <LoginForm alertCallback={setAlert} loginLink={links.login} callback={handleClose} userCheck={userCheck} />
        }

        const NavItems = () => {
            if (links.register) {
                return (
                    <Nav fill variant="underline" defaultActiveKey="login" className='mb-3'>
                        <Nav.Item>
                            <Nav.Link eventKey="login" onClick={handleLoginBtn}>Login</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="register" onClick={handleRegisterBtn}>Register</Nav.Link>
                        </Nav.Item>
                    </Nav>
                )
            }
        }

        return (
            <>
                <Modal
                    centered
                    show={show}
                    onShow={handleLoginBtn}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{modalFormTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {showAlert()}
                        {NavItems()}
                        {DisplayedForm()}
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

export default UserModal;