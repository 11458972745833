import React from "react";
import Hero from "../common/Hero";

function About(){
    return (
        <Hero
        type="default"
        title="About"
        data={{lead:`Richard-Mark I. is a developer based out of Montreal. 
        Interests primarily lie in: Backend Development, Machine Learning & Game Dev.  
        richardmark.dev serves as both my portfolio and playground. 
        I will probably be launching non-sensical stuff.`}} />
    );
};

export default About;