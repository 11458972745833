import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import httpClient from "./httpClient";
import Form from 'react-bootstrap/Form';
import { useForm } from "react-hook-form"

function RegisterForm({alertCallback, registerLink, callback, userCheck}) {

  const [ connecting, setConnecting ] = useState(false);

  const { register, handleSubmit } = useForm();

  const registerUser = async (data) => {
    try {
      const resp = await httpClient.post(registerLink, data);
      if(resp.data.error){
        alert(`Error: ${resp.data.error}`);
      }
      userCheck();
      callback();
    } catch (error) {
      alertCallback(error);
      console.log(error);
    }
    setConnecting(false);
  };

  const onSubmit = (data) => {
    if(connecting || registerLink == null) return;

    setConnecting(true);
    registerUser(data);

  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>

      <Form.Group className="mb-3" controlId="formDisplayName">
        <Form.Label>Display Name</Form.Label>
        <Form.Control type="text" disabled={connecting} placeholder="Enter display name" {...register("displayName", {required: true})} />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formUsername">
        <Form.Label>Username</Form.Label>
        <Form.Control type="text" disabled={connecting} placeholder="Enter username" {...register("username", {required: true})} />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control type="password" disabled={connecting} placeholder="Password"  {...register("password", {required: true})} />
      </Form.Group>

      <Button variant="primary" disabled={connecting} type="submit">
        Register
      </Button>
    </Form>
  );
}

export default RegisterForm;