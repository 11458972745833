import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import httpClient from "../common/httpClient";
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Alert from 'react-bootstrap/Alert';
import { useState, useEffect, useReducer } from 'react';
import { useForm } from "react-hook-form";
import ListGroup from 'react-bootstrap/ListGroup';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build'
import CategoryBadge from '../common/CategoryBadge';
import Spinner from 'react-bootstrap/Spinner';
import Image from 'react-bootstrap/Image';
import InputGroup from 'react-bootstrap/InputGroup';

function ManagePosts({ postsListUrl, 
  postSaveUrl, 
  categoryListUrl, 
  postUrl
}) {
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  const { register, handleSubmit, setValue } = useForm();
  const [categoryList, setCategoryList] = useState([]);
  const [ckeditorInstance, setCkeditorInstance] = useState(null);
  const [post, setPost] = useState(null);
  const [postImages, setPostImages] = useState([]);
  const [postList, setPostList] = useState([]);
  const [postContent, setPostContent] = useState("");
  const [visiblePostList, setVisiblePostList] = useState([]);
  const [postFilter, setPostFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [alert, setAlert] = useState(0);

  const showAlert = () => {
    if(alert){
      return (
          <Alert key={"warning"} variant={"warning"}>
            {alert}
          </Alert>
      )
    }
    return ""
  }

  // CATEGORIES
  const GetCategories = async (categoryListUrl) => {
    if(categoryListUrl){
      try {
        const resp = await httpClient.get(categoryListUrl);
        if(resp.data.error){
          alert(`Error: ${resp.data.error}`);
        }
        setCategoryList(resp.data.categories)
      } catch(error) {
        console.log(error);
      }
    }
  }

  useEffect(()=>{
    GetCategories(categoryListUrl);
    // eslint-disable-next-line
  }, [categoryListUrl]);

  const createCategoryOptions = (filter=null) => {
    let options = [];
    categoryList.map( (el, i) => {
      if(el.id!==filter){
        options.push(<option key={i} value={el.id}>{el.name}</option>)
      }
      return ""
    })
    return options;
  }

  const createOrderOptions = () => {
    let options = [];
    for(let i = 0; i < 10; i++){
      options.push(<option key={i} value={i}>{i}</option>);
    }
    return options;
  }

  const getPosts = async (postsListUrl) => {
    if(postsListUrl){
      try {
        const resp = await httpClient.get(postsListUrl);
        if(resp.data.error){
          alert(`Error: ${resp.data.error}`);
        }
        setPostList(resp.data.posts);
      } catch(error) {
        console.log(error);
      }
    }
  }

  useEffect( () => {
    getPosts(postsListUrl)
  }, [postsListUrl]);

  const GetListGroup = () => {
    let options = []
    visiblePostList.map( (e,i) => {
      options.push(<ListGroup.Item action key={i} eventKey={e.id} onClick={ () => {setPost(e.id)}}>
        {e.display_name}
        <span className='ms-2'>
          <CategoryBadge category={e.category_name} /> 
        </span>
      </ListGroup.Item>);
    })

    return (
      <ListGroup variant="flush" defaultActiveKey={"addNew"}>
        <ListGroup.Item action eventKey={"addNew"}  onClick={ () => {clearForm()}}>Create New</ListGroup.Item>
        {options}
      </ListGroup>
    )
  }  
  
  useEffect( () => {
    setVisiblePostList(postList);
  }, [postList])

  useEffect( () => {
    if(postFilter != '') {
      setVisiblePostList(postList.filter( (post) => post.display_name.includes(postFilter) || post.category_name.includes(postFilter)));
    } else {
      setVisiblePostList(postList);
    }
  }, [postFilter])

  useEffect( () => {
    if(ckeditorInstance != null && postContent != null){

      ckeditorInstance.setData(postContent);
    }
  }, [postContent, ckeditorInstance])

  const getPost = async () => {
    if(postUrl != null && post != null){
      setLoading(true);
      try {
        await httpClient.get(`${postUrl}?postId=${parseInt(post)}`).then( resp => {

          if(resp.data.error){
            alert(`Error: ${resp.data.error}`);
          }
          if(resp.data.post){
            setValue('displayName', resp.data.post.display_name)
            setValue('categoryId', resp.data.post.category_id)
            setValue('orderId', resp.data.post.order_priority);
            setValue('isVisible', resp.data.post.is_visible);
            setPostContent(resp.data.post.data.text);
  
            if (resp.data.post.image){
              setPostImages([{
                "filename": resp.data.post.filename,
                "image" : resp.data.post.image
              }])
            } else {
              setPostImages([]);
            }
          }
        })
      } catch(error) {
        console.log(error);
      }
      setTimeout(()=>{
        setLoading(false);
      }, 300)
    }
  }

  const clearForm = () => {
    setAlert(null);
    setValue('displayName', '')
    setValue('categoryId', '')
    setValue('orderId', '')
    setValue('isVisible', 'false')
    setPostImages([]);
    ckeditorInstance.setData('<p>My awesome post. kek</p>');
  }

  useEffect(()=>{
    getPost();
  }, [post]);

  const savePost = async (data) => {
    
    try {
      const resp = await httpClient.post(postSaveUrl, data);
      if(resp.data.error != null){
        setAlert(resp.data.error);
      } else {
        clearForm();
        setAlert("Post saved");
      }
    } catch (error) {
      
      setAlert("Failed to save post. See logs");
    }
    setSubmitting(false);
  }

  const onSubmit = (formdata) => {
    const formData = new FormData();
    
    var filename = "";
    if(formdata.postImage.length > 0){
      formData.append("files", formdata.postImage[0]);
      filename = formdata.postImage[0].name;
    }
    
    formdata = { 
      ...formdata, 
      postId: post,
      picture: filename, 
      text: ckeditorInstance.getData(),
    };
    formData.append("info", JSON.stringify(formdata));

    if(!postSaveUrl) return;
    setSubmitting(true);
    savePost(formData);
    forceUpdate();
  }

  const removeImage = (index) => {
    console.log(postImages)
    const is = postImages;
    is.splice(index, 1);
    setPostImages(is);
    forceUpdate();
  }

  const getPreviewImages = () => {
    if (postImages.length > 0){
      return (
        <div className='border p-3 mb-3'>
          <p>Preview Images</p>
          {postImages.map( (e,i) => {
            return (
              <div key={i} style={{height:300, width:300}} className='position-relative'>
                  <span onClick={(e) => {removeImage(i)}} className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                    X
                  </span>
                <Image src={e.image} thumbnail fluid />
              </div>
            )
          })}
        </div>
      )
    }
    return ""
  }

  const GetForm = () => {
    if(loading){
      return (
        <div className='justify-content-center my-5'>
          <Spinner animation="grow">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )
    }
    return (
      <Form className='py-3' onSubmit={handleSubmit(onSubmit)}>

        <Form.Group className="mb-3" controlId="displayName">
          <Form.Label>Post Name</Form.Label>
          <Form.Control type="text" disabled={submitting} placeholder="Enter post name" {...register("displayName", {required: true})} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="categoryId">
          <Form.Label>Category</Form.Label>
          <Form.Select disabled={submitting} {...register("categoryId", {required: true} )}>
            <option key={0} value="">--Category--</option>
            {createCategoryOptions()}
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3" controlId="orderId">
          <Form.Label>Order</Form.Label>
          <Form.Select disabled={submitting} {...register("orderId")}>
            <option key={0} value="">--Order--</option>
            {createOrderOptions()}
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3" controlId="isVisible">
          <Form.Label>Is Visible?</Form.Label>
          <Form.Select disabled={submitting} {...register("isVisible")}>
            <option key={"false"} value= {"false"}>Hidden</option>
            <option key={"true"} value={"true"}>Visible</option>
          </Form.Select>
        </Form.Group>

        {postImages.length > 0 ? (
          getPreviewImages()
        ) : (
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Post Image(s)</Form.Label>
            <Form.Control type="file" {...register("postImage")} />
          </Form.Group>
        )}


        <CKEditor
          editor={ Editor }
          data="<p>My awesome post</p>"
          config={{
            mediaEmbed: {
              previewsInData:true
            }
          }}
          onReady={ editor => {
            setCkeditorInstance(editor)
          } }
          />

        <Button className="mt-3" variant="primary" disabled={submitting} type="submit">
          Create
        </Button>
      </Form>
    )
  }

  return (
    <Container fluid className='h-100'>
      {showAlert()}
        <Row xs={1} sm={2} className='h-100'>
          <Col xs={12} md={4} className='mh-100 border overflow-y-scroll px-0'>
          <InputGroup className="rounded-0">
            <InputGroup.Text id="inputGroup-sizing-default">
              Search
            </InputGroup.Text>
            <Form.Control
              aria-label="Default"
              aria-describedby="inputGroup-sizing-default"
              value={postFilter}
              onChange={ (e) => { setPostFilter(e.target.value) }}
            />
          </InputGroup>
            {GetListGroup()}
          </Col>
          <Col xs={12} md={8} className='mh-100 border overflow-y-scroll'>
            {GetForm()}
          </Col>
        </Row>
    </Container>
  );
}

export default ManagePosts;